import styled from 'styled-components';
const BoldFont = 'Rubik-Medium';
const MontserratRegular = 'Montserrat-Regular';
const MontserratBold = 'Montserrat-Bold';
const ExtraBoldFont = 'Rubik-Bold';
const MontserratSemiBold = 'Montserrat-SemiBold';
const RegularFont = 'Rubik-Light';
import { DropdownButton, DropdownItem } from "react-bootstrap";
import { ImageUrl } from '../../utils/constants';
const backgroundUrl = `url(${ImageUrl}/images/HomePageV2/Home_banner.png)`;

const  ButtonLearnMoree = styled.button`
width: ${(props) => props.tryFree ? '190px' : '190px'};
height: 45px;
background-color: ${(props) => props.tryFree ? '#fff' : 'rgb(246, 180, 121)' };
color: ${(props) => props.tryFree ? '#1573B9' : '#fff' };
display: ${({display}) => display}; 
padding: ${(props) => props.paddingVertical ? `${props.paddingVertical}` : '10px 10px'};
border-radius: 3px;
border: none;
font-size: 16px;
line-height: 24px;
font-family: Rubik-Medium;
font-style: normal;
box-shadow: 4px 6px 5px -1px rgba(0, 0, 0, 0.11);
cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};

&:hover, &:focus, &:active {
  outline: none;
}

@media (max-width: 350px) {
  width: 59%;
  padding: 14px 10px;
}

@media (max-width: 720px) {
  width: 80%;
  width: 320px;
  height: 50px;
}

@media (min-width: 1420px) {
  font-size: 18px;
  padding: 15px 10px;
}
@media (min-width: 1201px) {
  width: 36%;
  width: 190px;
  font-size: 16px;
  padding: 10px 10px;
}
`;

const  IntroTextContainerr = styled.div`
  display: inline-block;
  width: 100%;
  letter-spacing: 1.5px;
  padding: 0px 10px 30px 0px;
  margin: auto;
  background-color: #f5fbff;
  >div:first-child{
    width: 500px;
    margin: auto;
    margin-top: 75px;
  h3 {
    width: 500px;
     font-family: ${BoldFont};
     letter-spacing: 0;
     margin-top: 0px;
     font-weight: 500;
     color: rgb(36, 57, 90);
     font-size: 24px;
     line-height: 50px;
     margin: 0px;
  }
}
>div:nth-child(2){
  width: 500px;
  margin: auto;
  p {
    width: auto;
    line-height: 22px;
    font-family: ${MontserratRegular};
    letter-spacing: 0;
    font-weight: 500;
    margin: 25px 0 25px 0;
    color: rgb(36, 57, 90);
    font-size: 16px;
    line-height: 26px;
    text-align: center;
  }
}
>div:nth-child(3){
  width: 500px;
  height: 280px;
  background-color: white;
  border-radius: 12px 12px 0px 0px;
  margin: auto;
}
>div:nth-child(4){
}
  
@media(max-width: 1200px) {
  display: inline-block;
  width: 100%;
  letter-spacing: 1.5px;
  padding: 0px;
  margin: auto;
  background-color: #f5fbff;
  >div:first-child{
    width: 100%;
    margin: auto;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  h3 {
      width: 100%;
     font-family: ${BoldFont};
     letter-spacing: 0;
     margin-top: 0px;
     font-weight: 500;
     color: rgb(36, 57, 90);
     font-size: 24px;
     line-height: 50px;
     margin: 0px;
     display: flex;
     text-align:center;
  }
}
>div:nth-child(2){
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  p {
    width: auto;
    line-height: 22px;
    font-family: ${MontserratRegular};
    letter-spacing: 0;
    font-weight: 500;
    margin: 25px 0 25px 0;
    color: rgb(36, 57, 90);
    font-size: 16px;
    line-height: 26px;
    text-align: center;
  }
}
>div:nth-child(3){
  width: 100%;
  height: 280px;
  background-color: white;
  border-radius: 12px 12px 0px 0px;
  margin: auto;
}
>div:nth-child(4){
}
}
`;

const  Wrapper = styled.div`
display: inline-block;
width: 65%;
letter-spacing: 1.5px;
padding: 0px 10px 30px 0px;
margin: auto;
@media(max-width: 1200px) {
  display: inline-block;
  width: 100%;
  letter-spacing: 1.5px;
  padding: 0px;
  margin: auto;
}
`;

const  Main = styled.div`
  max-width: 1200px;
  display: flex;
  margin: auto;
  @media(max-width: 1200px) {
    max-width: 1200px;
    display: block;
    margin: auto;
    padding: 15px;
  }
`;
const WrapperContainerr = styled.div`
  width: 100%;
  margin: auto;
  @media(max-width: 500px){
    padding-top: 30px;
  }, 
`;
const  Form = styled.div`
display: block;
width: 35%;
margin-top: -13px;
.policyText{
  >p{
    width: auto;
    font-family: ${MontserratRegular};
    letter-spacing: 0;
    font-weight: 500;
    margin: 15px 0 50px 0;
    color: rgb(36, 57, 90);
    font-size: 9px;
    text-align: center;
    margin:0px;
    >a{
      text-decoration: underline;
      color: rgb(36, 57, 90);
      font-size: 9px;
    }
  }
}
>div:first-child{  
  margin-top: 100px;
  h3 {
    width: auto;
     margin-bottom: 25px;
     font-family: ${BoldFont};
     letter-spacing: 0;
     margin-top: 0px;
     font-weight: 500;
     color: #24395a;
     font-size: 24px;
     line-height: 27px;
     text-align: center;
  }
}
>div:nth-child(2){
  p {
    width: auto;
    line-height: 22px;
    font-family: ${MontserratRegular};
    letter-spacing: 0;
    font-weight: 500;
    margin: 25px 0 35px 0;
    color: #24395a;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    padding-top: 9px;
  }
}

.customPipedrive{
  @media(min-width: 800px) {
    margin-left:10px !important;
  }

}
@media(max-width: 1200px) {
  display: block;
  width: 100%;
  margin-top: -13px;
  .policyText{
    >p{
      width: auto;
      font-family: ${MontserratRegular};
      letter-spacing: 0;
      font-weight: 500;
      margin: 15px 0 50px 0;
      color: rgb(36, 57, 90);
      font-size: 9px;
      text-align: center;
      margin:0px;
      >a{
        text-decoration: underline;
        color: rgb(36, 57, 90);
        font-size: 9px;
      }
    }
  }
  >div:first-child{  
    margin-top: 100px;
    h3 {
      width: auto;
       margin-bottom: 25px;
       font-family: ${BoldFont};
       letter-spacing: 0;
       margin-top: 0px;
       font-weight: 500;
       color: #24395a;
       font-size: 24px;
       line-height: 27px;
       text-align: center;
    }
  }
  >div:nth-child(2){
    p {
      width: auto;
      line-height: 22px;
      font-family: ${MontserratRegular};
      letter-spacing: 0;
      font-weight: 500;
      margin: 25px 0 35px 0;
      color: #24395a;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      padding-top: 9px;
    }
  }
}


`;

const CompanyMainWrapperr = styled.div`
  width:100%;
  display:flex;;
  flex-direction:column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
  >div:first-child{
    width:65%;
    text-align: center;
    padding-top: 90px;
    >p{
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      letter-spacing: 0.08em;
      color: #102B52;
      font-family: 'Rubik-Medium';
      >span{
        color:#F6797D;
        font-family: ${MontserratBold}
      }
    }
  }
  @media(max-width: 1200px) {
    margin-top: -40px;
  }
  @media(min-width: 1420px) {
    padding-top: 0px;
  }
  @media(min-width: 1500px) {
    padding-top: 0px;
  }
  @media(min-width: 1600px) {
    padding-top: 0px;
  }
`;

const ShowLogo = styled.div`
  width:100%;
  min-height: 217px;
  display:flex;
  float: left;
  padding: 25px 0px 0 0px;
  margin: 20px auto;
  justify-content: space-around;
  flex-wrap: wrap;
  >p{
    margin: auto;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.08em;
    color: #102B52;
    font-family: 'Rubik-Medium';
  }
  >div{
    display:flex;
    width:100%;
  display:flex;
  float: left;
  padding: 25px 0px 0 0px;
  margin: 20px auto;
  justify-content: space-around;
  flex-wrap: wrap;
  >div{
    >img{
      width: auto;
      height: 36px;
    }
  }

  @media (max-width: 1200px) {
    >div{
      >img{
        width: auto;
        height: 36px;
        margin: 20px 7px;
      }
    }
  }

}
`;
const  ContactUsContainer = styled.div`
  display: inline-block;
  width: 100%;
  height: 300px;
  border-radius: 20px;
  background-image: ${backgroundUrl};
  background-size : cover;
  background-repeat : no-repeat;
  background-size: 100% 300px;
  position: relative;
  display: flex;
  margin-top: 50px;
  >div:first-child{
    width: 47%;
   >h3{
    font-family: ${BoldFont};
    letter-spacing: 0;
    margin-top: 0px;
    font-weight: 500;
    color: white;
    font-size: 36px;
    line-height: 50px;
    padding-top: 40px;
    padding-bottom: 11px;
    font-family: Rubik-Medium;
font-style: normal;
font-weight: 500;
font-size: 42px;
line-height: 50px;
padding-left: 40px;
   }
   >div{
    padding-left: 40px;
    padding-bottom: 15px;
    width: 100%;
     >p{
      font-family: Montserrat-Regular;
      letter-spacing: 0;
      margin-top: 0px;
      color: white;
      font-size: 14px;
      line-height: 26px;
      font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 26px;
font-family: 'Montserrat-Regular';
     }
   }
   >span{
    font-family: Montserrat-Regular;
    letter-spacing: 0;
    margin-top: 0px;
    color: white;
    font-size: 14px;
    line-height: 26px;
    font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 26px;
font-family: 'Montserrat-Regular';
padding-left: 40px;
padding-top: 15px;
padding-bottom: 4px;
   }
   
   >p{
    font-family: Rubik;
      letter-spacing: 0;
      margin-top: 0px;
      color: white;
      font-size: 14px;
      line-height: 26px;
      font-family: Rubik;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 26px;
font-family: Rubik;
color: white;
padding-left: 40px;
     }
   }
  }
  >div:nth-child(2){
    width: 53%;
    >img{
      width: auto;
      height: 300px;
      float: right;
    }
  }
  @media (max-width: 1200px) {
    display: inline-block;
    width: 100%;
    height: 220px;
    border-radius: 20px;
    background-image: ${backgroundUrl};
    background-size : cover;
    background-repeat : no-repeat;
    background-size: 100% 300px;
    position: relative;
    display: flex;
    margin-top: 50px;
    >div:first-child{
      width: 55%;
     >h3{
      font-family: ${BoldFont};
      letter-spacing: 0;
      margin-top: 0px;
      font-weight: 500;
      color: white;
      font-size: 36px;
      line-height: 50px;
      padding-top: 0px;
      padding-bottom: 0px;
      font-family: Rubik-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 50px;
  padding-left: 15px;
     }
     >div{
      padding-left: 15px;
      padding-bottom: 0px;
      width: 100%;
       >p{
        font-family: Montserrat-Regular;
        letter-spacing: 0;
        margin-top: 0px;
        color: white;
        font-size: 14px;
        line-height: 26px;
        font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  font-family: 'Montserrat-Regular';
       }
     }
     >span{
      font-family: Montserrat-Regular;
      letter-spacing: 0;
      margin-top: 0px;
      color: white;
      font-size: 14px;
      line-height: 24px;
      font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  font-family: 'Montserrat-Regular';
  padding-left: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
     }
     
     >p{
      font-family: Rubik;
        letter-spacing: 0;
        margin-top: 0px;
        color: white;
        font-size: 14px;
        line-height: 26px;
        font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  font-family: Rubik;
  color: white;
  padding-left: 15px;
       }
     }
    // }
    >div:nth-child(2){
      width: 45%;
      >img{
        width: auto;
        height: 215px;
        float: right;
      }
    }
  }

  `;

const GlobalMainWrapperr = styled.div`
  width:100%;
  max-width: 1200px;
  margin-top: 80px;
  display:flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  >div:first-child{
    width:100%;
    text-align:center;
    >p{
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #69C2FF;
      font-style: normal;
      font-weight: 600;
      font-family: ${MontserratSemiBold};
    }
    >h1{
      font-size: 30px;
      line-height: 54px;
      text-align: center;
      letter-spacing: 0.03em;
      color: #24395A;
      text-transform: capitalize;
      font-family: ${ExtraBoldFont};
    }
  }
  >div:last-child{
    display: flex;
    flex-direction : row;
    padding: 90px 0px 150px 0px;
    justify-content: center;
    >div{
      width:18%;
      text-align: center;
      >img{
        width: 136px;
        height: 153px;
      }
    }
    @media(min-width: 1000px){
    }
  }
  @media(max-width: 1000px){
    >div:last-child{
      display: flex;
      flex-wrap: wrap;
      padding: 0 40px;
      justify-content: center;
      >div{
        margin: 40px 15px;
        width: auto
      }
    }
  }
  @media(max-width: 500px){
    padding: 0px 8px;
    margin-top: 150px;
    >div:first-child{
      >p{
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.08em;
      }
      >h1{
        font-size: 28px;
        line-height: 31px;
        letter-spacing: 0.03em;
        font-family: Rubik;
font-style: normal;
font-weight: bold;
font-size: 28px;
line-height: 31px;
color: #24395A;
margin-bottom: 30px;
      }
    }
    >div:last-child{
      padding: 0;
      margin-top: 50px;
      >div{
        font-size: 15px;
        width: 90px;
        height: 102px;
        margin: 20px 9px 30px 9px;
        >img{
          width: 100%;
          height: 100%;
        }
      }

    }
  }
`;
const TeamTabContainer = styled.div`
  width: ${(props) => props.setTabWidth ? '100%' : '100%'};
  background-color: #fff;
  display: flex;
  margin-top: 75px;
    > div {
        float: left;
        padding: 10px 0px;
        font-family: ${BoldFont};
        font-size: 18px;
        color: #fff;
        cursor: pointer;
    }
    div:first-child {
    width: 49%;
    display: flex;
    margin: auto;
    div:first-child {
      width: 420px;
      border-radious: 20px;
      height: 50px;
      border-radius: 30px;
      margin: 0px;
      div:first-child {
        background-color: ${(props) => props.active ? '#FD7175' : 'rgba(156, 156, 156, 0.24)'};
        color: ${(props) => props.active ? '#fff' : '#24395A'};
        height: 3px;
        width: 20px;
        margin: 0px;
        border-radius: 30px;
        font-family: Rubik;
        font-family: ${(props) => props.active ? 'Rubik-Medium' : 'Rubik'};
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 21px;
text-transform: capitalize;
    }
    >div:nth-child(2){
        background-color: ${(props) => !props.active ? '#FD7175' : 'rgba(156, 156, 156, 0.24)'};
        color: ${(props) => !props.active ? '#fff' : '#24395A'};
        height: 3px;
        width: 20px;
        margin: 0px;
        border-radius: 30px;
        justify-content: flex-end;
        font-family: Rubik;
        font-family: ${(props) => props.active ? 'Rubik' : 'Rubik-Medium'};
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 21px;
text-align: center;
text-transform: capitalize;
    }
  }
  }
  div:last-child{
        display: flex;
        width: 15%;
        height: 0px;
        margin: auto;
        padding: 0px;
        > div:first-child {
          width: 204px;
          height: 50px;
          color: #3F4555;
    font-family: Rubik-Medium;
    font-size: 24px;
    display: flex
    text-align: left;
    justify-content: center;
  s
        }
        >div:nth-child(2){
          width: 204px;
          height: 50px;
          color: #3F4555;
    font-family: Rubik-Medium;
    font-size: 24px;
    display: flex
    text-align: left;
    justify-content: center;
    padding-right: 15px;
        }
        >div:nth-child(3){
          width: 204px;
          height: 50px;
          color: #3F4555;
    font-family: Rubik-Medium;
    font-size: 24px;
    display: flex
    text-align: left;
    justify-content: center;
    padding-right: 15px;
        }
    }

    @media (max-width: 500px){
        > div {
            width: 100%;
        }
    }
  @media (max-width: 1000px) {
    width: 100%
  }
  @media (max-width: 600px) {
    padding: 0% 0%;
  }
`;

const GlobalMainWrapper = styled.div`
width:100%;
float: left;
padding: 25px 0px 0 0px;
margin: 20px auto;
justify-content: space-around;
flex-wrap: wrap;
>p{
  margin: auto;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.08em;
  color: #102B52;
  font-family: 'Rubik-Medium';
}
>div{
  display:flex;
  width:100%;
float: left;
padding: 25px 0px 0 0px;
margin: 20px auto;
justify-content: space-around;
flex-wrap: wrap;
>div{
  >img{
    width: 88px;
    height: 110px;
  }
  @media (max-width: 1200px) {
    >img{
      width: 88px;
      height: 100px;
      margin: 20px 9px 30px;
    }
  }
}}
`;

const Layout = styled.div`
  width: 100%
  z-index: 0
  // @media (max-width: 600px) {
  //   width: 80%;
  // }
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  text-align: center;
  margin: 20px auto;
  background-color: white;
`;

const StyledLabel = styled.label`
  float: left;
  display: block;
  font-size: 16px;
  color: #243a5a;
  line-height: 21px;
  font-family: Rubik;
  font-weight: 500;
  position: relative;
  pointer-events: none;
  >span{
    font-size: 18px;
    color:#F6797D;
  }
`;

const StyledInput = styled.input`
  background-color: #fff;
  border: none;
  border-radius: 0;
  border: 1px solid;
  padding: 0px 20px 0px 20px
  box-shadow: none;
  font-size: 14px;
  margin-bottom: 0;
  height: 40px;
  width: 100%;
  border: 1px solid #9c9c9c;
  border-radius: 3px;
  &:active{
    border: 1px solid #9c9c9c;
  }
  &::placeholder {
    font-size: 14px;
    color: #2b281e;
    font-family: ${RegularFont};
    font-weight: 400;
    vertical-align: middle;
  }
  
`;

const SectionContainer = styled.div`
  width: ${({ fullWidth }) => fullWidth ? '330px' : '330px'};
  display: block;
  margin: auto;
  padding-bottom: 25px;
  >ul{
    width: 330px;
  }
  .PhoneInput{
    margin-left: -35px;
      .PhoneInputInput{
        padding: 12px;
      }
  }
  .dropdown{
  >option {
  }
  .open{
  &::placeholder {
    font-size: 14px;
    color: #2b281e;
    font-family: ${RegularFont};
    font-weight: 400;
    vertical-align: middle;
   
  }
  }
  // @media (max-width: 500px) {
  //   width: 100%;
  //   padding: 0 10% 20px 10%
  // }
  @media (max-width: 1200px) {
    width: 100%;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const TextAreaContainer = styled.div`
    width: 330px;
    display: block;
    margin: auto;
    // @media (max-width: 500px) {
    //     padding: 0 10% 20px 10%;
    // }
    @media (max-width: 1200px) {
      padding: 0px;
  }
`;

const TextAreaLabel = styled.label`
   display: block;
   font-size: 18px;
   color: #243a5a;
   line-height: 21px;
   font-family: Rubik;
   font-weight: 500;
   position: relative;
   text-align: left;
   
`;

const TextAreaInput = styled.textarea`
     display: block;
     width: 100%;
     height: 146px;
     border-radius: 3px;
     background-color: #fff;
     border: none;
     padding: 15px;
     resize: ${({ resize }) => resize ? 'none' : 'vertical'};
     outline: none;
     border: 1px solid #24395a;
     
   &::placeholder {
    font-size: 14px;
    color: #2b281e;
    font-family: ${RegularFont};
    font-weight: 400;
    vertical-align: middle;
   }
`;

const CustomForm = styled.form`
  width: 100%;
  background-color: white;
  margin-top: 30px;
  padding-bottom: 0px;
  .buttonContainer {
    width: 100%;
  }
  // @media (max-width: 500px) {
  // .buttonContainer {
  //     padding: 0 10% 0px 10%;
  // }
  // }
  @media (max-width: 1200px) {
    .buttonContainer {
        padding: 0px;
    }
    }
`;

const SendMessage = styled.p`
  font-size: 19px;
  text-align: center;
  margin: 20px 0 50px;
  font-family: ${BoldFont};
  letter-spacing: 2px;
  line-height: 14px;
  color: #2b281e;
  text-transform: uppercase;
`;

const CustomButton = styled.button`
width: 330px;
height: 45px;
  text-align: center;
  margin-top: 5px;
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  font-family: Rubik;
  font-weight: 500;
  font-weight: normal;
  opacity:  ${({ disabled }) => disabled ? '50%' : ''};
  background-color: #F6797D;
  border: none;
  margin-top: 25px;
  border-radius: 4px;
  &:focus, &:active, &:hover {
    outline: none;
  }
`;

const ActivityDropdown = styled(DropdownButton)`
  padding: 12px 10px;
  width: 100%;
  text-align: left;
  color: rgb(153,153,153);
  text-transform: capitalize;
  outline: none;
  width: 330px;
  border: 1px solid #24395a;
  width: 327px;
  background-color: #fff;
  > span {
    float: right;
    top: 20px;
    position: absolute;
    right: 10px;
  }
 
  &:active, &:hover, &:focus, &:active:focus {
    outline: none;
    
    border-color:white;
    box-shadow: none;
    color: rgb(153,153,153);
    border: 1px solid #24395a;
    background-color: #fff;
  }
`;

const CustomMenuItem = styled(DropdownItem)`

> li {
  &:active, &:hover, &:focus {
    outline: none;
    background-color: #69C2FF;
    opacity: 0.1;
  }
}

  width: 100%;
  width: 330px;
  text-transform: capitalize;
  font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 16px;

  > a {
    &:active, &:hover, &:focus {
      outline: none;
    }
  }
  >ul{
    width: 330px;
  }
`;

const  TopCard = styled.div`
.topcard-demo-wrapper{
  width:100%;
  display:flex;
  justify-content:center;
  .topcard-demo{
    width:63%;
    height: 200px;
    border-radius: 6px;
    padding: 30px 50px 30px 30px;
    display:flex;
    margin-top:41px;
    background: linear-gradient(90deg, #102B52 29.41%, #006FBC 100%);
    
  }
}
`;

export { ButtonLearnMoree, IntroTextContainerr, Main, WrapperContainerr, Form, CompanyMainWrapperr, ShowLogo, ContactUsContainer, GlobalMainWrapperr, TeamTabContainer, GlobalMainWrapper, Layout, FormContainer, StyledInput, StyledLabel, SectionContainer, CustomForm, SendMessage, CustomButton, TextAreaContainer, TextAreaLabel, TextAreaInput, Wrapper, ActivityDropdown, CustomMenuItem, TopCard};
