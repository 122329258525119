/* eslint-disable react/jsx-key */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { subscribeNewsLetter } from "../../redux/actions/authActions";
import {
  Main,
  IntroTextContainerr,
  WrapperContainerr,
  Form,
  ContactUsContainer,
  Wrapper,
} from "./style";
import TeamPackage from "./test";
import ReactPlayer from "react-player";
import { VideoPlayer } from "../Content/DailyDashboard/styles";
import LazyImage from "../common/LazyImage/LazyImage";
import { ImageUrl } from "../../utils/constants";
import ContactUsForm from './form';
class RequestDemo extends Component {
  constructor() {
    super();
    this.state = {
      showVideo: false,
    };
  }

  componentDidMount() {
    this.setState({ active: "Insurance" });
    const script = document.createElement("script");
    script.src = "https://webforms.pipedrive.com/f/loader";
    script.async = true;
    document.body.appendChild(script);
  }

  changeModal = () => {
    this.setState({ showVideo: !this.state.showVideo });
  };

  TopPanelSection = () => (
    <Main>
      <Wrapper>
        <IntroTextContainerr>
          <div>
            <h3>The Gold Standard for Workplace Wellbeing</h3>
          </div>
          <div>
            <p>
              We’re here to help you create a workplace wellness program <br />
              tailored to your organizational goals and company culture.
            </p>
          </div>
          <div>
            <VideoPlayer height={"100%"}>
              <ReactPlayer
                url={"https://vimeo.com/808590329"}
                controls
                playing={true}
                muted={true}
              />
            </VideoPlayer>
          </div>
          <TeamPackage />
        </IntroTextContainerr>
        <ContactUsContainer>
          <div>
            <h3>Contact Us</h3>
            <div>
              <p>
                We’re Happy To Answer Questions And
                <br />
                Get You Acquainted With Woliba
              </p>
            </div>
            <span>Email Us at</span>
            <p>sales@woliba.io</p>
          </div>
          <LazyImage src={ImageUrl + "/images/ContactV2/contactUs.svg"} alt={"contactUs"}/>
        </ContactUsContainer>
      </Wrapper>
      <Form>
        <div>
          <h3>Schedule A Demo</h3>
        </div>
        <div>
          <p>
            Get A Live Demo & Your Questions Answered <br />
            With One Of Our Amazing Product Experts
          </p>
        </div>
        <ContactUsForm  history={this.props.history}/>
        {/* <div
          // style={{height:'65%'}}
          className="pipedriveWebForms customPipedrive"
          data-pd-webforms="https://webforms.pipedrive.com/f/32OY9UsWDV2Iy7MthNORiLjN2uoXOLio8W4OQwrHWSyFFdeegeSGbOYgsAYJOC9kD"
        ></div> */}

        {/*<embed
          style={{ marginLeft: "6%", height:'160vh', width:'100%' }}
          wmode="transparent"
          className="pipedriveWebForms"
          src="https://webforms.pipedrive.com/f/32OY9UsWDV2Iy7MthNORiLjN2uoXOLio8W4OQwrHWSyFFdeegeSGbOYgsAYJOC9kD"
        /> */}

        <div>
          <div className="policyText">
            <p>{"By Submitting Your Information, You Agree To Woliba's"}</p>
            <p>
              <a href="https://woliba.io/terms-of-use">Terms Of Use</a> And{" "}
              <a href="https://woliba.io/privacy-policy">Privacy Policy</a>.
            </p>
            <p>You Can Opt Out Anytime.</p>
          </div>
        </div>
      </Form>
    </Main>
  );

  render() {
    return <WrapperContainerr>{this.TopPanelSection()}</WrapperContainerr>;
  }
}
RequestDemo.propTypes = {
  history: PropTypes.object,
  subscribeNewsLetter: PropTypes.func,
  video: PropTypes.object,
  updateUserPoints: PropTypes.func,
};
const mapDispatchToProps = (dispatch) => ({
  subscribeNewsLetter: (history, payload) =>
    dispatch(subscribeNewsLetter(history, payload)),
});
export default connect(null, mapDispatchToProps)(RequestDemo);
