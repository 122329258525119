import React, { Component } from 'react';
import { TeamTabContainer, ShowLogo, GlobalMainWrapper } from "./style";
import PropTypes from 'prop-types';
import LazyImage from '../common/LazyImage/LazyImage';
import { ImageUrl } from '../../utils/constants';
class TeamPackage extends Component {
  constructor() {
    super();
    this.state = {
      active: true
    }
    this.timer = null;
  }

  componentDidMount() {
    this.timer = window.setInterval(this.toggleTabs, 3000); // Adjust the duration between slide transitions
  }

  componentWillUnmount() {
    window.clearInterval(this.timer);
  }

  toggleTabs = () => {
    const { active } = this.state;
    this.setState({ active: !active });
  };

  renderTabs = () => (
    <TeamTabContainer tabActive setTabWidth active={this.state.active}>
      <div>
        <div>
          <div onClick={() => this.toggleTabs(true)} />
          <div onClick={() => this.toggleTabs(false)} />
        </div>
      </div>
    </TeamTabContainer>
  );

  companyLogo = () => (
    <ShowLogo>
      <p>Trusted By Brokers and Global Organizations</p>
      <div>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/alera.svg"}/>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/dhx.svg"}/>
        {!this.state.mobileViewStatus ? <LazyImage src={ImageUrl + "/images/NewHomePageV2/mastercontrol.svg"}/> : <LazyImage src={ImageUrl + "/images/NewHomePageV2/Laerdal.png"}/>}
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/pure.svg"}/>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/laerdal.svg"}/>
      </div>
    </ShowLogo>
  )

  globalPositionSection = () => (
    <GlobalMainWrapper>
      <p>We Are Consistently Ranked As A Leader</p>
      <div>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/CorporateWellness_HighPerformer_HighPerformer.svg"} width="70%"/>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/CorporateWellness_EasiestSetup_Mid-Market_EaseOfSetup.svg"} width="70%"/>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/CorporateWellness_BestRelationship_Mid-Market_Total.svg"} width="70%"/>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/EmployeeRecognition_EasiestAdmin_EaseOfAdmin.svg"} width="70%"/>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/CorporateWellness_BestUsability_Mid-Market_Total.svg"} width="70%"/>
      </div>
    </GlobalMainWrapper>
  )

  render() {
    const { active } = this.state;

    return (
      <div className="teamPackage">
        <div className="planTableContainer">

          
          {
            active ?
              <div >{this.companyLogo()}</div>:<div >{this.globalPositionSection()}</div>
          }
          {this.renderTabs()}
        </div>
      </div>
    );
  }
}

TeamPackage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default TeamPackage;